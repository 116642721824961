import React, { FC } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';

import Typography from 'components/atoms/Typography';
import {
  ContactSectionProps,
  ContactSectionVariant,
  ContactTypographyType,
} from 'components/molecules/ContactSection';

import { useScreenService } from 'hooks/useScreenService';

import {
  StyledContact,
  StyledContactAddress,
  StyledContactCard,
  StyledContactInformation,
  StyledContactSection,
} from './ContactSection.styles';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a target="_blank" className="link" data-target="hover" href={data.uri} rel="noreferrer">
        {children}
      </a>
    ),
  },
};
const ContactSection: FC<ContactSectionProps> = ({
  title,
  card,
  contactInformation,
  contactVariant,
  testId,
}) => {
  const { isLgUp } = useScreenService();

  const { FOOTER, CONTACT, CAREERS } = ContactSectionVariant;

  const isFooterVariant = contactVariant === FOOTER;
  const isContactVariant = contactVariant === CONTACT;
  const isCareersVariant = contactVariant === CAREERS;

  const contactTypography: ContactTypographyType = {
    title: {
      color: isFooterVariant ? `gray-200` : `gray-900`,
      size: { base: 20, md: 24, xl: 30 },
      padding: isFooterVariant
        ? { top: { base: 20, lg: 54, xl: 64, xxl: 80 } }
        : { top: { base: 48, md: 80, xl: 64 } },
      lineHeight: 1.33,
      fontWeight: 500,
      asEl: isContactVariant ? 'div' : 'h2',
    },
    type: {
      color: isFooterVariant ? `gray-25` : `gray-900`,
      size: isFooterVariant ? { base: 14, md: 20 } : { base: 16, md: 18, lg: 20 },
      padding: isFooterVariant ? { top: 48 } : { top: { base: 32, lg: 48 } },
      lineHeight: 1.6,
      fontWeight: 400,
    },
    information: {
      color: isFooterVariant ? `white` : `black`,
      size: isFooterVariant ? { base: 20, md: 24 } : { base: 18, md: 20, lg: 24 },
      padding: { top: { base: 8, md: 14 }, bottom: { base: 8, md: 14 } },
      lineHeight: 1.6,
      fontWeight: 500,
    },
    note: {
      color: isFooterVariant ? `gray-100` : `gray-900`,
      size: { base: 14, md: 16 },
      fontWeight: 300,
      lineHeight: 1.5,
    },
  };

  return (
    <StyledContactSection data-variant={contactVariant} data-testid="contact-section">
      {isLgUp || !isFooterVariant ? (
        <StyledContactCard
          {...card}
          key={card.id}
          isCoveredImage={isFooterVariant || isCareersVariant}
        >
          <Typography
            size={{ base: 16, lg: 18 }}
            padding={{ top: { base: 4, lg: 9 } }}
            color="gray-600"
            fontWeight={400}
            lineHeight={1.3}
            asEl="span"
          >
            {card.subtitle}
          </Typography>
        </StyledContactCard>
      ) : null}
      <StyledContactInformation>
        <Typography {...contactTypography.title}>{title}</Typography>
        <StyledContactAddress data-variant={contactVariant}>
          {contactInformation.map(({ information, note, type }) => (
            <StyledContact key={type}>
              <Typography {...contactTypography.type}>{type}</Typography>
              <Typography {...{ ...contactTypography.information, testId }}>
                {renderRichText(information, options)}
              </Typography>
              <Typography {...contactTypography.note}>{note}</Typography>
            </StyledContact>
          ))}
        </StyledContactAddress>
      </StyledContactInformation>
    </StyledContactSection>
  );
};
export default ContactSection;
