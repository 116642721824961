import styled from 'styled-components';
import { breakpoint } from 'styles';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { FormSectionVariant } from 'components/molecules/FormSection/models.d';

const { CONTACT, CAREERS, FOOTER } = FormSectionVariant;

export const StyledFormButton = styled(Button)`
  justify-content: center;
  max-width: 100%;
  margin-top: var(--gap-xxxl);

  ${breakpoint.md} {
    margin-top: var(--gap-xl);
  }
`;

export const StyledFormSection = styled.div`
  position: relative;
  display: grid;
  align-content: start;
  justify-content: center;

  &[data-variant='${FOOTER}'] {
    justify-content: flex-start;
    padding: var(--gap-lg) 0;
    border-bottom: 1px solid var(--c-gray-700);

    ${breakpoint.lg} {
      padding: 96px var(--gap-xxxl) 154px 0;
      border-right: 1px solid var(--c-gray-700);
      border-bottom: none;
    }

    ${breakpoint.xl} {
      padding: 96px 108px 162px 0;
    }
  }

  &[data-variant='${CONTACT}'] {
    padding: var(--gap-lg) var(--gap-xxs) var(--gap-xxxxxl);
    background: var(--c-gray-900);
    border-bottom: 1px solid var(--c-gray-700);
    border-radius: var(--border-radius-lg);
    box-shadow: 0 20px 40px var(--c-opacity-blue-05);

    ${breakpoint.md} {
      padding: var(--gap-xxxxxl) var(--gap-xxxxxl) 160px;
    }

    ${breakpoint.lg} {
      padding: var(--gap-xxxxxl) 222px 160px;
    }

    ${breakpoint.xl} {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--gap-lg);
      padding: 160px 148px 124px;
    }

    ${breakpoint.xxl} {
      gap: 124px;
    }
  }

  &[data-variant='${CAREERS}'] {
    padding: var(--gap-lg) var(--gap-xxs) var(--gap-xxxxxl);
    background: var(--c-gray-25);
    border-radius: var(--border-radius-lg);
    box-shadow: 0 20px 40px var(--c-opacity-blue-05);
    backdrop-filter: blur(32px);

    ${breakpoint.md} {
      padding: var(--gap-xxxxl) 82px 160px;
    }

    ${breakpoint.lg} {
      grid-template-columns: 516px;
    }

    ${breakpoint.xl} {
      grid-template-columns: 426px 515px;
      gap: 123px;
      padding: var(--gap-xxxxxl) 0;
    }
  }
`;

export const StyledFormText = styled.div`
  &[data-variant='${FOOTER}'] {
    max-width: 480px;
  }

  &[data-variant='${CAREERS}'] {
    ${breakpoint.xl} {
      padding-top: var(--gap-lg);
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  &[data-variant='${FOOTER}'] {
    max-width: 480px;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 48px;
  color: var(--c-white);
  border-bottom: 1px solid var(--c-gray-400);

  &[data-variant='checkbox'] {
    flex-direction: row;
    color: red;
  }
`;

export const StyledIcon = styled(Icon)`
  justify-self: center;
`;

export const StyledSuccessSection = styled(StyledFormSection)`
  place-content: center;
  justify-content: center;
  height: 100%;

  &[data-variant='footer'] {
    justify-content: center;
  }

  &[data-variant='contact'],
  &[data-variant='careers'] {
    ${breakpoint.xl} {
      grid-template-columns: 1fr;
      gap: 0;
      padding: 160px 0;
    }
  }

  &[data-variant='careers'] {
    path {
      fill: var(--c-gray-800);
    }
  }
`;

export const StyledSuccessButton = styled(Button)`
  justify-self: center;
`;
