import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

import { ImageType } from 'components/atoms/Image';
import { TypographyProps } from 'components/atoms/Typography';
import { CardProps } from 'components/molecules/Card';

export enum ContactSectionVariant {
  FOOTER = 'footer',
  CONTACT = 'contact',
  CAREERS = 'careers',
}

export type ContactSectionProps = {
  title: string;
  contactInformation: {
    note: string;
    type: string;
    information: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  }[];
  card: CardProps & { subtitle: string };
  image: ImageType;
  contactVariant: ContactSectionVariant;
  testId?: string;
};

export type ContactTypographyType = {
  title: TypographyProps;
  type: TypographyProps;
  information: TypographyProps;
  note: TypographyProps;
};
