import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

import { ButtonProps } from 'components/atoms/Button/models.d';
import { IconProps } from 'components/atoms/Icon';
import { TypographyProps } from 'components/atoms/Typography/models.d';

export type IFormData = {
  email: string;
  phone: string;
  checkbox: boolean;
  file: File;
};

export enum FormSectionVariant {
  FOOTER = 'footer',
  CONTACT = 'contact',
  CAREERS = 'careers',
}

export interface FormSectionProps {
  title: string;
  description: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  form: {
    id: string;
    inputTitle: string;
    variant: string;
    content: string;
  }[];
  uploadText: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  uploadTextMobile: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  checkbox: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  button: ButtonProps[];
  formVariant: FormSectionVariant;
  successTitle: string;
  sectionId?: string;
  successText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  successIcon?: IconProps;
  successButton?: ButtonProps;
}

export type FormTypographyType = {
  title: TypographyProps;
  description: TypographyProps;
  button: TypographyProps;
};
