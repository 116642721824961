import styled from 'styled-components';
import { breakpoint } from 'styles';

import { StyledTypography } from 'components/atoms/Typography';
import Card from 'components/molecules/Card';
import { ContactSectionVariant } from 'components/molecules/ContactSection/models.d';

const { CONTACT, CAREERS, FOOTER } = ContactSectionVariant;

export const StyledContactCard = styled(Card)`
  margin-top: -40px;

  ${breakpoint.md} {
    max-width: 405px;
    margin-top: -100px;
  }

  ${breakpoint.xl} {
    margin-top: -200px;
  }
`;

export const StyledContactSection = styled.div`
  position: relative;
  padding: var(--gap-lg) 0;

  ${breakpoint.lg} {
    padding-left: var(--gap-md);
    margin-top: calc(-1 * var(--gap-xl));
  }

  &[data-variant='${FOOTER}'] {
    border-bottom: 1px solid var(--c-gray-700);

    ${breakpoint.lg} {
      border-bottom: none;
    }

    ${StyledContactCard} {
      ${breakpoint.lg} {
        max-width: 510px;
        max-height: 350px;
        margin-top: calc(-1 * var(--gap-xxxxxl));
        overflow: hidden;
      }

      ${breakpoint.xl} {
        max-width: 624px;
        max-height: 442px;
        margin-top: calc(-1 * var(--gap-lg));
      }

      ${StyledTypography} {
        display: none;
      }
    }
  }

  &[data-variant='${CAREERS}'] {
    ${StyledContactCard} {
      max-width: 500px;
      margin: -40px auto 0;

      ${breakpoint.md} {
        grid-template-rows: 1fr;
        max-width: 335px;
        height: 368px;
        margin-top: -90px;
      }

      ${breakpoint.lg} {
        max-width: 515px;
        height: 384px;
        margin-top: -60px;
      }

      ${breakpoint.xl} {
        max-width: 515px;
        height: 484px;
        margin: -170px 0 0;
      }

      ${StyledTypography} {
        display: none;
      }
    }
  }

  &[data-variant='${CONTACT}'],
  &[data-variant='${CAREERS}'] {
    display: grid;
    gap: var(--gap-md);
    padding: 0 var(--gap-xxs);
    margin-top: 0;

    ${breakpoint.md} {
      grid-template-columns: 1fr 50%;
      padding: 0 var(--gap-md);
    }
  }
`;

export const StyledContactInformation = styled.div`
  ${breakpoint.lg} {
    max-width: 570px;
  }
`;

export const StyledContactAddress = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.xl} {
    flex-direction: row;
    gap: var(--gap-xl);
  }

  &[data-variant='${FOOTER}'] {
    ${breakpoint.md} {
      flex-direction: row;
      gap: var(--gap-xl);
    }

    ${breakpoint.lg} {
      flex-direction: column;
      gap: 0;
    }

    ${breakpoint.xl} {
      flex-direction: row;
      gap: var(--gap-xl);
    }
  }
`;

export const StyledContact = styled.div`
  display: grid;
`;
