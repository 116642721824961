// @ts-nocheck
import { Buffer } from 'buffer';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { yupResolver } from '@hookform/resolvers/yup';
import loadable from '@loadable/component';
import kebabCase from 'lodash/kebabCase';
import { boolean, lazy, mixed, object, string } from 'yup';

import { FormSectionVariant, FormTypographyType } from 'components/molecules/FormSection';

import { useScreenService } from 'hooks/useScreenService';

import {
  StyledForm,
  StyledFormButton,
  StyledFormSection,
  StyledFormText,
  StyledIcon,
  StyledSuccessButton,
  StyledSuccessSection,
} from './FormSection.styles';

import { FormSectionProps, IFormData } from './models.d';

const Input = loadable(() => import('components/atoms/Input'));
const Typography = loadable(() => import('components/atoms/Typography'));
const DragAndDrop = loadable(() => import('components/molecules/DragAndDrop'));

const formErrors = {
  email: {
    valid: 'Please enter a valid email address.',
    length: 'Email has incorrect length',
    required: 'Email is a required field',
  },
  phone: {
    required: 'Phone number is a required field',
    length: 'Phone number has incorrect length',
    vaild: 'Please enter a valid phone number',
  },
  checkbox: 'Please confirm that you accept the privacy policy to proceed.',
  file: {
    required: 'You need to provide a file',
    fileSize: 'The file is too large',
  },
};

const FormSection: FC<FormSectionProps> = ({
  title,
  description,
  form,
  checkbox,
  uploadText,
  uploadTextMobile,
  button,
  formVariant,
  sectionId,
  successTitle,
  successText,
  successIcon,
  successButton,
}) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { CAREERS, FOOTER, CONTACT } = FormSectionVariant;
  const isCareersVariant = formVariant === CAREERS;
  const isFooterVariant = formVariant === FOOTER;
  const isContactVariant = formVariant === CONTACT;
  const sectionIdTransformed = kebabCase(sectionId);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { isMdUp } = useScreenService();

  const mbToBytes = (number: number): number => number * 1048576;

  const fileSizeInBytes = mbToBytes(20);

  const getPhoneValue = (str: string) => (str.includes(' ') ? str.split(' ').pop() : '');

  const schema = object({
    email: string()
      .matches(
        /^([a-zA-Z])+([-_.]{0,}[a-zA-Z0-9]+){0,}@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z0-9]{1,20}$/gm,
        formErrors.email.valid
      )
      .required(formErrors.email.required)
      .max(255, formErrors.email.length),
    phone: string()
      .test('length', (value, { createError }) => {
        if (isCareersVariant && getPhoneValue(value).length < 1) {
          return createError({
            message: formErrors.phone.required,
            path: 'phone',
          });
        }

        if (
          getPhoneValue(value).length > 0 &&
          (getPhoneValue(value).length < 4 || getPhoneValue(value).length > 13)
        ) {
          return createError({
            message: formErrors.phone.length,
            path: 'phone',
          });
        }

        return true;
      })
      .test('format', formErrors.phone.vaild, (value) => {
        if (getPhoneValue(value).length > 0) {
          return /^[0-9]+$/.test(getPhoneValue(value));
        }

        return true;
      }),

    checkbox: boolean().oneOf([true], formErrors.checkbox),
    file: isCareersVariant
      ? lazy((val) =>
          mixed()
            .test('required', formErrors.file.required, (value) => value)
            .test('fileSize', formErrors.file.fileSize, (value) => {
              const base64str = val && val.content.substr(22);
              const decoded = val && Buffer.from(base64str, 'base64');

              return value && value?.content[0] && decoded.length <= fileSizeInBytes;
            })
        )
      : null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IFormData>({ resolver: yupResolver(schema) });

  const onSubmit = (data: IFormData) => {
    setIsSuccess(true);
    setIsClicked(false);
    const body = data;
    body.isCareersVariant = isCareersVariant;

    fetch(`/api/form`, {
      method: `POST`,
      body: JSON.stringify(body),
      headers: {
        'content-type': `application/json`,
      },
    }).then((res) => res.json());
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setIsSubmited(isSubmitSuccessful);
      reset({ email: '', phone: '', checkbox: false, file: undefined });
    }
  }, [isSubmitSuccessful, reset]);

  const formTypography: FormTypographyType = {
    title: {
      size: isFooterVariant ? { base: 24, xl: 36 } : { base: 30, lg: 48 },
      padding: {
        bottom: { base: 16, md: 24 },
        top: isFooterVariant ? { base: 40, lg: 96 } : 0,
      },
      color: isCareersVariant ? 'black' : 'white',
      lineHeight: 1.25,
      fontWeight: 500,
      letterSpacing: '-0.02em',
      asEl: isContactVariant ? 'h1' : 'div',
    },
    description: {
      size: isFooterVariant ? { base: 18, xl: 24 } : { base: 16, md: 18, lg: 20 },
      padding: isFooterVariant ? { bottom: 40 } : { bottom: { base: 48, md: 80 } },
      color: isCareersVariant ? 'gray-800' : 'gray-200',
      lineHeight: 1.5,
      fontWeight: 300,
    },
    button: {
      padding: { top: 10, bottom: 10 },
      size: 16,
      fontWeight: 500,
      color: isCareersVariant ? 'white' : 'gray-900',
    },
  };

  if (isSuccess) {
    return (
      <StyledSuccessSection data-variant={formVariant} id={sectionIdTransformed}>
        {successIcon ? <StyledIcon {...successIcon} renderComponent /> : null}
        <Typography
          asEl="h3"
          {...formTypography.title}
          padding={{ top: { base: 24, md: 32, lg: 40 }, bottom: { base: 16, xl: 24 } }}
          align="center"
        >
          {successTitle}
        </Typography>
        {successText ? (
          <Typography
            {...formTypography.description}
            padding={{ bottom: { base: 16, xl: 24 } }}
            align="center"
          >
            {renderRichText(successText)}
          </Typography>
        ) : null}
        {successButton ? (
          <StyledSuccessButton
            {...{ ...successButton }}
            typography={{ ...formTypography.button, padding: undefined }}
            background={isCareersVariant ? 'black' : 'white'}
            onClick={() => setIsSuccess(false)}
            type="button"
            theme={isCareersVariant ? 'light' : 'dark'}
          />
        ) : null}
      </StyledSuccessSection>
    );
  }

  return (
    <StyledFormSection
      data-variant={formVariant}
      data-testid="form-section"
      id={sectionIdTransformed}
    >
      <StyledFormText data-variant={formVariant}>
        <Typography asEl="h3" {...formTypography.title}>
          {title}
        </Typography>
        <Typography {...formTypography.description}>{renderRichText(description)}</Typography>
      </StyledFormText>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {form.map(({ id, inputTitle, content, variant }) => (
          <Input
            key={id}
            name={inputTitle}
            placeholder={content}
            type={variant}
            error={errors[variant]?.message}
            {...{ register, isSubmited, setIsSubmited, formVariant }}
          />
        ))}
        {isCareersVariant ? (
          <DragAndDrop
            register={register}
            required
            isClicked={isClicked}
            setValue={setValue}
            error={errors?.file?.message}
            uploadText={isMdUp ? uploadText : uploadTextMobile}
          />
        ) : null}
        <Input
          checkboxText={checkbox}
          type="checkbox"
          register={register}
          error={errors?.checkbox?.message}
          formVariant={formVariant}
        />
        <StyledFormButton
          {...button}
          background={isCareersVariant ? 'black' : 'white'}
          typography={{ ...formTypography.button }}
          type="submit"
          onClick={() => setIsClicked(true)}
        />
      </StyledForm>
    </StyledFormSection>
  );
};
export default FormSection;
